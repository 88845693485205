<div class="d-flex input-group input-group-sm d-grid gap-2 pe-2">

  <input [(ngModel)]="searchablePropertyName" class="form-control form-control-sm"
    [ngClass]="{'is-invalid': !inputIsValid}" placeholder="Type to search..." (focus)='propertyListVisible = true'
    (blur)='hideSearchablePropertyList()'>
  <div *ngIf='propertyListVisible' class='mt-4 border rounded'
    style='position: fixed; z-index: 10500; max-height: 400px; overflow: auto;' (mousedown)="preventBlur($event)">
    <div class="bg-dark dropdownItems"
      *ngFor="let property of searchableProperties | searchSortByName: searchablePropertyName">
      <div class="p-2 dropdownSubItems" (mousedown)='selectSearchableProperty(property)'>
        <span>{{property.name}}</span>
      </div>
    </div>
  </div>

  <select class='form-control form-control-sm form-select' [(ngModel)]="rule.operator"
    (change)="onRuleTypeChange($event.target.value)">
    <option [value]="operator" *ngFor="let operator of operators">{{ operator | titlecase }}</option>
  </select>

  <ng-container *ngIf="userInputType == 'boolean'">
    <input type="checkbox" class="btn-check" id="btn-check" autocomplete="off" [checked]="rule.userInput"
      (change)="onBooleanUserInputChange()">
    <label class="btn btn-primary" for="btn-check">
      {{ rule.userInput === 'true' ? 'True' : 'False'}}
    </label>
  </ng-container>

  <input *ngIf="userInputType == 'number' || userInputType == 'text'" type="text"
    class="form-control form-control-sm form-input" [(ngModel)]="rule.userInput"
    (change)="onUserInputChange($event.target.value)">
</div>