import { environment } from 'src/environments/environment'

import { CommonModule } from '@angular/common'
import { Component } from '@angular/core'
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { AuthenticationService } from '@services/authentication.service'
import { AwsService } from '@services/aws.service'
import { RequestService } from '@services/request.service'
import { SharedModule } from '@shared/shared.module'

@Component({
  imports: [CommonModule, ReactiveFormsModule, SharedModule],
  selector: 'shared-submit-feedback',
  standalone: true,
  templateUrl: './submit-feedback.component.html',
  styleUrls: ['./submit-feedback.component.css']
})
export class SubmitFeedbackComponent {
  public feedbackForm: FormGroup = new FormGroup({
    type: new FormControl('issue', Validators.required),
    title: new FormControl('', Validators.required),
    description: new FormControl('', Validators.required),
    file: new FormControl(null)
  })

  readonly MAX_FILE_SIZE = 10 * 1024 * 1024 // 10MB
  readonly ALLOWED_FILE_TYPES = ['image/', 'video/', 'application/pdf']
  public fileError: string | null = null
  public submissionSuccess: boolean = false
  public submitting = false

  constructor(
    private authenticationService: AuthenticationService,
    private awsService: AwsService,
    private requestService: RequestService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  get currentUser$() {
    return this.authenticationService.user$
  }

  get type() {
    return this.feedbackForm.get('type')?.value
  }

  get title() {
    return this.feedbackForm.get('title')?.value
  }

  get description() {
    return this.feedbackForm.get('description')?.value
  }

  get file() {
    return this.feedbackForm.get('file')?.value
  }

  returnToPreviousPage() {
    const previousUrl = this.route.snapshot.queryParams['redirect']
    if (previousUrl) {
      this.router.navigateByUrl(previousUrl)
    } else {
      this.router.navigate(['/'])
    }
  }

  onFileSelected(event: any): void {
    const file = event.target.files[0]

    if (!file) {
      return
    }

    const isAllowedFileType = this.ALLOWED_FILE_TYPES.some(type => file.type.startsWith(type))

    if (!isAllowedFileType) {
      this.fileError = 'Invalid file type. Please upload an image, video, or PDF.'
      event.target.value = null
      this.feedbackForm.patchValue({ file: null })
      return
    }

    if (file.size > this.MAX_FILE_SIZE) {
      this.fileError = 'File size exceeds 10MB. Please choose a smaller file.'
      event.target.value = null
      this.feedbackForm.patchValue({ file: null })
    } else {
      this.fileError = null
      this.feedbackForm.patchValue({ file })
    }
  }

  async onSubmit(): Promise<void> {
    if (this.feedbackForm.invalid) {
      this.feedbackForm.markAllAsTouched()
      return
    }

    this.submitting = true
    const { type, title, description } = this.feedbackForm.value
    const url = `${environment.api}/feedback`

    try {
      let token = null

      if (this.file) {
        const uploadResult = await this.awsService.uploadFiles([this.file], { purpose: 'submit-feedback' }).toPromise()
        token = uploadResult.token
      }

      await this.requestService.create(url, { type, title, description, token }).toPromise()
      this.submissionSuccess = true
      setTimeout(() => this.returnToPreviousPage(), 2500)
    } catch (error) {
      this.submitting = false
      // Handle error feedback here
    } finally {
      this.submitting = false
    }
  }
}