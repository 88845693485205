import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs'
import { catchError, distinctUntilKeyChanged, filter, map, skip, switchMap, tap } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import * as THREE from 'three'

import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Action, ActionKeys } from '@classes/Action'
import { Feature, FeatureCustomField, FeatureCustomFieldResult, FeatureFileResult, FeatureTypes, InteractionEffect } from '@classes/Feature'
import { FeatureProperty, FeaturePropertyTypes } from '@classes/FeatureProperty'
import { Interaction, InteractionType } from '@classes/Interaction'
import { Model } from '@classes/Model'
import { ModelPermission } from '@classes/ModelPermission'
import { AuthenticationService } from '@services/authentication.service'
import { AwsService } from '@services/aws.service'
import { EndpointOptions, ErrorOptions, ManyCRUDOptions, RequestService } from '@services/request.service'
import { fileReferenceFromResult, FileReferenceResult, SceneService, selectedScene$ } from '@services/scene.service'
import { MarkerTypes, TagTypes } from '@services/spatial-annotation.service'
import { ToastOptions, ToastService } from '@services/toast.service'
import { userFromResult, UserResult } from '@services/user.service'
import { clone } from '@utils/Objects'
import { TagOptions } from '@utils/TagFeatures'

const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) }

export type CreateFeatureOptions = {
  files?: File[]
  toast?: boolean
  updateLocally?: boolean
} & ToastOptions

export type CreateTagOptions = {
  file?: File
  rotation?: THREE.Vector3Tuple
  scale?: THREE.Vector3Tuple
  tagOptions?: TagOptions
} & ToastOptions

export type UpdateFeatureOptions = {
  toast?: boolean
} & ToastOptions

export type UpdateFeaturesOptions = {
  name: string
  description: string
  visible: boolean
  opacity: number
  filterable: boolean
  interactable: boolean
  objectOfInterest: boolean
  onClick: InteractionEffect
  onHover: InteractionEffect
  position: [number, number, number]
  rotation: [number, number, number]
  scale: [number, number, number]
}

export type UpdateFeaturesInteractionsOptions = {
  showDetailsInteraction: "remove" | "click" | "panel-click"
  showDetailsDisplayType: "modal" | "panel"
  showDetailsModalSize: "medium" | "large" | "fullscreen"
}

export type UpdateMarkersOptions = {
  size: number
  displayType: 'icon' | 'image' | 'label'
  icon: string
  image: File
  color: string
  text: string
  backgroundColor: string
  backgroundShape: 'square' | 'rounded' | 'circle'
}

export type BulkCreateOptions = {
  createGroup?: boolean
  files?: File[]
  useMetadata?: boolean
}

export type CreateGeoJsonOptions = {
  nameKey?: string
}

export type CreateMarkersOptions = {
  groupFeatures?: boolean
  embeddedImages?: File[]
  useGpsLocation?: boolean
  markerImage?: File
}

export type CreateGroupOptions = {
  children?: Feature[]
  name?: string
  parentID?: number
  toast?: boolean
}

// Result types
export type FeatureResult = {
  id: number,
  scene_id: number,
  parent_id?: number,
  model_id?: number,
  model?: ModelResult,
  name: string,
  type: FeatureTypes,
  description?: string,
  position: [number, number, number],
  rotation: [number, number, number],
  scale: [number, number, number],
  object_of_interest: boolean,
  visible: boolean,
  opacity: number,
  filterable: boolean,
  interactable: boolean,
  on_click: InteractionEffect,
  on_hover: InteractionEffect,
  last_changed: string,
  properties: FeaturePropertyResult[],
  interactions: InteractionResult[],
  custom_fields: FeatureCustomFieldResult[],
  files: FeatureFileResult[],
  origin: 'internal' | 'external' | 'modified'
}

export type ModelResult = {
  id: number
  name: string
  description: string
  thumbnail: string
  color: string
  override_color: boolean
  opacity: number
  last_changed: string
  files: FileReferenceResult[]
  position: [number, number, number]
  rotation: [number, number, number]
  scale: [number, number, number]
  shared_with_organization: boolean
  organization_id: string
  permissions: ModelPermissionResult[]
  state: string
}

export type ModelPermissionResult = {
  user_id: string
  model_id: number
  permission: string
  user: UserResult
}

export type FeaturePropertyResult = {
  id: number
  feature_id: number
  file_reference_id: number
  file_reference: FileReferenceResult
  type: FeaturePropertyTypes
  key: string
  value: string
}

export type InteractionResult = {
  id: number
  feature_id: number
  type: InteractionType
  actions: ActionResult[]
}

export type ActionResult = {
  id: number
  interaction_id: number
  prev_id: number
  next_id: number
  key: ActionKeys
  value: string
}

// Conversion functions
export function featureFromResult(result: FeatureResult) {
  return new Feature(
    result.scene_id,
    result.name,
    result.type,
    {
      id: result.id,
      parentID: result.parent_id,
      modelID: result.model_id,
      model: result.model ? modelFromResult(result.model) : null,
      description: result.description,
      position: result.position ? [result.position[0], result.position[1], result.position[2]] : null,
      rotation: result.rotation ? [result.rotation[0], result.rotation[1], result.rotation[2]] : null,
      scale: result.scale ? [result.scale[0], result.scale[1], result.scale[2]] : null,
      objectOfInterest: result.object_of_interest,
      visible: result.visible,
      opacity: result.opacity,
      filterable: result.filterable,
      interactable: result.interactable,
      onClick: result.on_click,
      onHover: result.on_hover,
      lastChanged: result.last_changed,
      properties: result?.properties?.map(p => featurePropertyFromResult(p)) ?? [],
      interactions: result?.interactions?.map(i => interactionFromResult(i)) ?? [],
      customFields: result?.custom_fields?.map(cf => customFieldFromResult(cf)),
      origin: result?.origin ?? 'internal'
    }
  )
}

export function modelFromResult(result: ModelResult) {
  return new Model(
    result.name,
    result.files ? result.files.map(fileReference => fileReferenceFromResult(fileReference as FileReferenceResult)) : [],
    {
      id: result.id,
      description: result.description,
      thumbnail: result.thumbnail,
      color: result.color,
      overrideColor: result.override_color,
      opacity: result.opacity,
      lastChanged: result.last_changed,
      position: result.position,
      rotation: result.rotation,
      scale: result.scale,
      sharedWithOrganization: result.shared_with_organization,
      organizationID: result.organization_id,
      permissions: result.permissions ? result.permissions.map(p => modelPermissionFromResult(p)) : [],
      state: result.state
    }
  )
}

export function modelPermissionFromResult(result: ModelPermissionResult) {
  return new ModelPermission(
    result.user_id,
    result.model_id,
    result.permission,
    {
      user: userFromResult(result.user)
    }
  )
}

export function featurePropertyFromResult(result: FeaturePropertyResult) {
  return new FeatureProperty(
    result.type,
    result.key,
    result.value,
    {
      id: result.id,
      featureID: result.feature_id,
      fileReferenceID: result.file_reference_id,
      fileReference: result.file_reference ? fileReferenceFromResult(result.file_reference) : null
    }
  )
}

export function customFieldFromResult(customField: FeatureCustomFieldResult) {
  const { id, feature_id, key, value } = customField
  return { id: +id, featureID: +feature_id, key, value } as FeatureCustomField
}

export function interactionFromResult(result: InteractionResult) {
  return new Interaction(
    result.type,
    result.actions ? result.actions.map(a => actionFromResult(a)) : [],
    {
      id: result.id,
      featureID: result.feature_id
    }
  )
}

export function actionFromResult(result: ActionResult) {
  return new Action(
    result.key,
    result.value,
    {
      id: result.id,
      interactionID: result.interaction_id,
      previousID: result.prev_id,
      nextID: result.next_id
    }
  )
}

function toSnakeCase(key: string) {
  return key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
}

const scenesFeaturesSource = new BehaviorSubject<Map<number, Feature[]>>(new Map())
export const scenesFeatures$ = scenesFeaturesSource.pipe(skip(1))
/** FEATURES ARE READONLY. DO NOT MODIFY. */
export const orginalFeatures: Map<number, Feature[]> = new Map()

const featuresSource: BehaviorSubject<Feature[]> = new BehaviorSubject<Feature[]>([])
export const features$: Observable<Feature[]> = featuresSource.pipe(skip(1))

@Injectable({
  providedIn: 'root'
})
export class FeatureService {
  private _selectedFeatureSource: BehaviorSubject<Feature | undefined> = new BehaviorSubject<Feature | undefined>(undefined)
  public selectedFeature$: Observable<Feature> = this._selectedFeatureSource.asObservable()
  public selectedFeatures: Set<number> = new Set<number>()
  public featurePositions: Map<number, number[]> = new Map()
  public retrievedDetails: Set<number> = new Set<number>()

  get scenesFeatures() {
    return scenesFeaturesSource.getValue()
  }

  set scenesFeatures(scenesFeatures: Map<number, Feature[]>) {
    scenesFeaturesSource.next(scenesFeatures)
  }

  /**
   * @returns A deep copy of the most recently emitted features.
   * @param  features A list of features
  */
  get features(): Feature[] {
    const features = clone(this.getFeatures())
    return features.map(f => new Feature(f.sceneID, f.name, f.type, f))
  }
  set features(features: Feature[]) {
    if (this.selectedFeature != null) {
      let feature = features.find(feature => feature.id == this.selectedFeatureID)
      if (!this.selectedFeature.equals(feature)) this.selectedFeature = feature
    }
    featuresSource.next(features)
    this.scenesFeatures.set(this._sceneService.selectedScene.id, features)
  }
  get selectedFeature(): Feature | undefined {
    const feature = clone(this._selectedFeatureSource.getValue())
    if (feature) return new Feature(feature.sceneID, feature.name, feature.type, feature)
    else return undefined
  }
  set selectedFeature(feature: Feature | undefined) {
    if (feature === undefined || this.features.some(f => f.id == feature.id)) {
      this._selectedFeatureSource.next(feature)
    }
  }
  get selectedFeatureID(): number {
    return this._selectedFeatureSource.getValue()?.id
  }
  public setSelectedFeatureByID(id: number) {
    this.selectedFeature = this.features.find(f => f.id == id)
  }

  public getFeature(id: number): Feature { return this.features.find(f => f.id == id) }

  public featuresMap$: Observable<Map<number, Feature>> = features$.pipe(map(features => {
    let featMap: Map<number, Feature> = {} as Map<number, Feature>

    features.reduce((map, feat) => {
      map[feat.id] = feat
      return map
    }, featMap)

    return featMap
  }))

  constructor(
    private _awsService: AwsService,
    private _authenticationService: AuthenticationService,
    private _http: HttpClient,
    private _requestService: RequestService,
    private _router: Router,
    private _sceneService: SceneService,
    private _toastService: ToastService,
  ) {
    selectedScene$.pipe(
      distinctUntilKeyChanged('id'),
      map(scene => this.scenesFeatures.get(scene.id) ?? []),
    ).subscribe(features => this.features = features?.map(
      feature => new Feature(feature.sceneID, feature.name, feature.type, feature)
    ))

    features$.pipe(
      filter(() => this.selectedFeature !== undefined),
    ).subscribe(features => {
      const feature = features.find(f => f.id == this.selectedFeatureID)
      if (!this.selectedFeature.equals(feature)) this.selectedFeature = feature
    })
  }

  public getFeatures() {
    return featuresSource.getValue() ?? []
  }

  public createFeature(feature: Feature, options: Partial<CreateFeatureOptions> = {}): Observable<Feature> {
    const url = `${environment.api}/feature/${feature.sceneID}`
    const body = { feature: JSON.stringify(feature) }

    options.toast = options.toast ?? true
    options.updateLocally = options.updateLocally ?? true

    return this._http.post<FeatureResult>(url, body).pipe(
      map(result => {
        const feature = featureFromResult(result)
        if (options?.updateLocally) this.features = ([feature].concat(this.features))
        if (options?.toast) this._toastService.toast({ title: "Feature Created", color: "green" })

        return feature
      })
    )
  }

  public createFeaturesFromAssets(sceneID: number, assetTypeID: number, locationFieldID: number, options?: { filters?: any, nameFieldID?: number }) {
    const url = `${environment.api}/feature/assets/${sceneID}/${assetTypeID}`
    const endpointOptions: EndpointOptions = {
      error: { operation: "Create Features From Assets", toast: true },
      successToast: { title: "Features Created", color: "green" },
    }

    return this._requestService.create<FeatureResult[]>(url, { locationFieldID, ...options }, endpointOptions).pipe(
      map((result: FeatureResult[]) => {
        const features = result.map(feature => featureFromResult(feature))

        this.updateFeaturesLocally(...features)

        return features
      })
    )
  }

  public createFeaturesFromGeoJson(geoJsonFiles: File[], sceneID: number, options: CreateGeoJsonOptions = {}) {
    const url = `${environment.api}/feature/geojson/${sceneID}`
    const formData: FormData = new FormData()
    const endpointOptions: EndpointOptions = {
      error: { operation: "Create Features From GeoJson", toast: true },
      progressBar: { title: `Uploading ${geoJsonFiles?.length > 1 ? 'Files' : 'File'}`, autohide: false },
      successToast: { title: "Features Created", color: "green" },
    }

    geoJsonFiles?.forEach(file => formData.append('file', file, file.name))
    formData.append('options', JSON.stringify(options))

    return this._requestService.create<FeatureResult[]>(url, formData, endpointOptions).pipe(
      map((result: FeatureResult[]) => {
        const features = result.map(feature => featureFromResult(feature))

        this.updateFeaturesLocally(...features)

        return features
      })
    )
  }

  public createImageFeatures(features: Feature[], options: BulkCreateOptions = {}) {
    const formData: FormData = new FormData()
    const [{ sceneID }] = features
    const url = `${environment.api}/feature/images/${sceneID}`
    let { createGroup, files, useMetadata } = options
    const endpointOptions: EndpointOptions = {
      error: { operation: "Create Image Features", toast: true },
      progressBar: { title: `Uploading ${files?.length > 1 ? 'Files' : 'File'}`, autohide: false },
      successToast: { title: "Features Created", color: "green" },
    }

    files?.forEach(file => formData.append('file', file, file.name))
    formData.append('features', JSON.stringify(features))
    formData.append('options', JSON.stringify({ createGroup, useMetadata }))

    return this._requestService.create<FeatureResult[]>(url, formData, endpointOptions).pipe(
      map((result: FeatureResult[]) => {
        const features = result.map(feature => featureFromResult(feature))

        this.updateFeaturesLocally(...features)

        return features
      })
    )
  }

  createRasterLayer$(geoTIFFs: File[], sceneID: number) {
    const url = `${environment.api}/feature/raster/${sceneID}`
    const endpointOptions = {
      error: { operation: 'Create Raster Layer', toast: true },
      successToast: { title: "Raster Files Uploaded", color: "green" },
    } as EndpointOptions

    return this._awsService.uploadFiles(geoTIFFs, { purpose: 'process-geotiff' }).pipe(
      switchMap(({ token }) => this._requestService.create(url, { sceneID, token }, endpointOptions)),
    )
  }

  /** Clones a feature and everything related to it and adds it to the desired scene. */
  public duplicateFeature(featureID: number, destinationSceneID: number, position?: number[]) {
    const url = `${environment.api}/feature/copy/${featureID}`
    const options = {
      error: { operation: "Duplicate Feature", toast: true },
      successToast: { title: "Feature was Duplicated" },
    } as EndpointOptions
    const payload = { destinationSceneID, position }

    return this._requestService.create<{ parent: FeatureResult, children: FeatureResult[] }>(url, payload, options).pipe(
      map(({ parent: parentResult, children: childrenResults }) => {
        const parent = featureFromResult(parentResult)
        const children = childrenResults.map(result => featureFromResult(result))

        this.updateFeaturesLocally(parent, ...children)

        return { parent, children }
      })
    )
  }

  /**
   * Handles various cases for creating Markers
   * Case 1: One Marker w/ an Icon
   * Case 2: Any number of Markers w/ the same Icon & their own embedded images
   * Case 3: Any number of Markers w/ the same Icon & their own embedded images w/ gps location
   * @param markers 
   * @param options 
   * @returns 
   */
  public createMarkers(markers: Feature[], options: CreateMarkersOptions = {}): Observable<Feature[]> {
    const createMarkers$ = (parentID?: number) => {
      const body = markers.map((marker, i) => {
        const formData = new FormData()
        const image = embeddedImages?.[i]

        marker.parentID = parentID

        if (image) formData.append('embeddedImage', image, image.name)
        if (options.markerImage) formData.append('markerImage', options.markerImage, options.markerImage.name)
        formData.append('feature', JSON.stringify(marker))
        formData.append('options', JSON.stringify({ useGpsLocation }))
        // formData.append('fileReference', JSON.stringify(fileReference)) // TODO: Handle using already made fileReference

        return formData
      })
      const endpointOptions: ManyCRUDOptions = {
        error: { operation: 'Create Markers', toast: true } as ErrorOptions,
        progressBar: { title: "Creating your Markers", message: "Uploading Marker's File(s)" } as ToastOptions,
        successToast: { title: "Markers Created", message: "Your Markers have been created" } as ToastOptions,
        simultaneous: false,
      }

      return this._requestService.createMany<FeatureResult>(url, body, endpointOptions).pipe(
        map(results => {
          const features = results.map(result => featureFromResult(result))

          this.updateFeaturesLocally(...features)

          return features
        })
      )
    }

    const url = `${environment.api}/feature/marker/${this._sceneService.selectedSceneID}`
    const { embeddedImages, groupFeatures, useGpsLocation } = options

    // TODO: Handle not sending the icon each time
    // TODO: Handle toasting and progress bars

    if (groupFeatures) {
      return this.createFeatureGroup({ name: 'Marker group', toast: false }).pipe(
        switchMap(group => createMarkers$(group.id))
      )
    } else {
      return createMarkers$()
    }
  }

  createTag(type: TagTypes, position: THREE.Vector3Tuple, options: CreateTagOptions = {}): Observable<Feature> {
    const sceneID = this._sceneService.selectedSceneID
    const name = type == 'icon' ? "New Icon" : 'New Label'
    const rotation = options.rotation
    const scale = options.scale
    const properties = [
      new FeatureProperty("boolean", "showOnTop", "false"),
      new FeatureProperty("boolean", "scaleWithCamera", "false"),
      new FeatureProperty("boolean", "trackCamera", "true"),
      new FeatureProperty("string", "backgroundColor", options?.tagOptions?.backgroundColor ?? "#ffffff"),
      new FeatureProperty("string", "backgroundShape", options?.tagOptions?.backgroundShape ?? "rounded"),
      new FeatureProperty("string", "color", options?.tagOptions?.color ?? "#000000"),
      new FeatureProperty("string", "icon", options?.tagOptions?.icon ?? "fas fa-info"),
      new FeatureProperty("string", "text", name),
    ]

    const feature = new Feature(sceneID, name, type, { position, properties, rotation, scale, objectOfInterest: true })

    return this.createFeature(feature, { updateLocally: true, ...options })
  }

  createMarker(type: MarkerTypes, position: THREE.Vector3Tuple, options: CreateTagOptions = {}): Observable<Feature> {
    const sceneID = this._sceneService.selectedSceneID
    const imageFile = options?.file
    const name = 'New Marker'

    if (type == "icon") {
      var size = "0.05"
    } else if (type == "label") {
      var size = "0.12"
    } else {
      var size = "0.07"
    }

    const properties = [
      new FeatureProperty("integer", "size", size),
      new FeatureProperty("string", "backgroundColor", options?.tagOptions?.backgroundColor ?? "#ffffff"),
      new FeatureProperty("string", "backgroundShape", options?.tagOptions?.backgroundShape ?? "circle"),
      new FeatureProperty("string", "color", options?.tagOptions?.color ?? "#000000"),
      new FeatureProperty("string", "displayType", type),
      new FeatureProperty("string", "icon", options?.tagOptions?.icon ?? "fas fa-info"),
      new FeatureProperty("string", "text", name),
    ]

    const feature = new Feature(sceneID, name, 'marker', { position, properties, objectOfInterest: true })

    if (imageFile) {
      return this.createMarkers([feature], { markerImage: imageFile }).pipe(map(result => result[0]))
    } else {
      return this.createFeature(feature)
    }
  }

  public createViewpoint(feature: Feature, options: Partial<CreateFeatureOptions> = {}): Observable<Feature> {
    const url = `${environment.api}/feature/viewpoint/${feature.sceneID}`
    const formData: FormData = new FormData()

    options.toast = options.toast ?? true
    options.updateLocally = options.updateLocally ?? true

    formData.append('feature', JSON.stringify(feature))
    options.files?.forEach(file => formData.append('file', file, file.name))

    return this._http.post<FeatureResult>(url, formData).pipe(
      map(result => {
        const feature = featureFromResult(result)
        if (options?.updateLocally) this.features = ([feature].concat(this.features))
        if (options?.toast) this._toastService.toast({ title: "Feature Created", color: "green" })

        return feature
      })
    )
  }

  public deleteFeature(feature: Feature, toast = true): Observable<Feature> {
    const url = `${environment.api}/feature/${feature.id}`

    return this._http.delete<{ deletedActions: ActionResult[] }>(url).pipe(
      catchError(this.handleError<{ deletedActions: ActionResult[] }>("Failed to delete feature.")),
      map(({ deletedActions }) => {
        const features = this.getFeatures()
        let children = this.getDescendants(feature)

        this.removeFeatureLocally(feature, ...children)

        /** Remove deleted actions from existing Features */
        const modifiedFeatures = []

        deletedActions.forEach(deletedAction => {
          let interaction: Interaction
          const feature = features.find(feature => interaction = feature.interactions.find(interaction => deletedAction.interaction_id == interaction.id))

          if (feature && interaction) {
            interaction.actions.splice(interaction.actions.findIndex(action => action.id == deletedAction.id), 1)

            modifiedFeatures.push(feature)
          }
        })

        this.updateFeaturesLocally(...modifiedFeatures)

        if (toast) {
          this._toastService.toast({ title: feature.name + " Deleted", color: "green" })
        }

        return feature
      })
    )
  }

  public deleteFeatures(sceneID: number, featureIDs: number[]): Observable<Feature[]> {
    const url = `${environment.api}/features/${sceneID}`
    const endpointOptions: EndpointOptions = {
      error: { operation: 'Delete Features', toast: true },
      successToast: { title: "Features Deleted", message: "Your Features have been deleted." },
    }

    return this._requestService.create<{ deletedActions: ActionResult[] }>(url, featureIDs, endpointOptions).pipe(
      catchError(this.handleError<{ deletedActions: ActionResult[] }>("Failed to delete feature.")),
      map(({ deletedActions }) => {
        const features = this.getFeatures().filter(feature => featureIDs.includes(feature.id))
        const children = features.reduce((acc, feature) => acc.concat(this.getDescendants(feature)), [])

        this.removeFeatureLocally(...features, ...children)

        /** Remove deleted actions from existing Features */
        const modifiedFeatures = []

        deletedActions.forEach(deletedAction => {
          let interaction: Interaction
          const feature = features.find(feature => interaction = feature.interactions.find(interaction => deletedAction.interaction_id == interaction.id))

          if (feature && interaction) {
            interaction.actions.splice(interaction.actions.findIndex(action => action.id == deletedAction.id), 1)

            modifiedFeatures.push(feature)
          }
        })

        return features
      })
    )
  }

  getPointCloudFiles(featureID: number) {
    const options = { error: { operation: 'Get Features Point Cloud Files', toast: true } } as EndpointOptions
    const isAuthenticated = this._authenticationService.currentUser != null

    if (isAuthenticated) {
      var url = `${environment.api}/feature/point-cloud/${featureID}`
    } else {
      var url = `${environment.api}/public/point-cloud/${featureID}`
    }

    return this._requestService.get<{ metadataURL: string, octreeURL: string, hierarchyURL: string }>(url, options)
  }

  public updateFeature(feature: Feature, options = {} as Partial<UpdateFeatureOptions>): Observable<Feature> {
    const url = `${environment.api}/feature/${feature.id}`

    options.toast = options?.toast ?? true
    options.color = options?.color ?? 'green'
    options.title = options?.title ?? 'Feature Updated'

    feature.children = [] // IMPORTANT NOTE: Removes unnecessary bloat data from request
    feature.lastChanged = new Date().getTime().toString()

    return this._http.put(url, feature, httpOptions).pipe(
      catchError(this.handleError("Failed to update feature.")),
      map(() => {
        this.updateFeaturesLocally(feature)
        if (options.toast) this._toastService.toast({ title: options.title, color: options.color })
        return feature
      })
    )
  }

  public updateFeatures(featureIDs: number[], sceneID: number, updates: UpdateFeaturesOptions) {
    const url = `${environment.api}/features/update/${sceneID}`
    const endpointOptions: EndpointOptions = {
      error: { operation: 'Update Features', toast: true } as ErrorOptions,
      successToast: { title: "Features Updated", message: "Your Features have been updated." } as ToastOptions,
    }

    const features = this.features.filter(feature => featureIDs.includes(feature.id))

    features.forEach(feature => {
      Object.keys(updates).forEach(key => {
        feature[key] = updates[key]
      })
    })

    this.updateFeaturesLocally(...features)

    // Format the changes before making the request
    const changes = Object.keys(updates).reduce((acc, key) => {
      const snakeKey = toSnakeCase(key) // Convert camelCase to snake_case
      acc[`${snakeKey}_in`] = updates[key] // Append _in to the key
      return acc
    }, {})

    return this._requestService.update<{ response: string }>(url, { featureIDs, updates: changes }, endpointOptions)
  }

  public updateFeaturesInteractions(featureIDs: number[], sceneID: number, updates: UpdateFeaturesInteractionsOptions) {
    const url = `${environment.api}/features/interactions/${sceneID}`
    const endpointOptions: EndpointOptions = {
      error: { operation: 'Update Features Interactions', toast: true } as ErrorOptions,
      successToast: { title: "Interactions Updated", message: "Your Feature's Interactions have been updated." } as ToastOptions,
    }

    const features = this.features.filter(feature => featureIDs.includes(feature.id))

    features.forEach(feature => {
      Object.keys(updates).forEach(key => {
        feature[key] = updates[key]
      })
    })

    this.updateFeaturesLocally(...features)

    // Format the changes before making the request
    const changes = Object.keys(updates).reduce((acc, key) => {
      const snakeKey = toSnakeCase(key) // Convert camelCase to snake_case
      acc[`${snakeKey}_in`] = updates[key] // Append _in to the key
      return acc
    }, {})

    return this._requestService.update<{ response: string }>(url, { featureIDs, updates: changes }, endpointOptions)
  }

  public updateMarkers(featureIDs: number[], sceneID: number, updates: UpdateMarkersOptions) {
    const formData = new FormData()
    const url = `${environment.api}/features/markers/${sceneID}`
    const endpointOptions: EndpointOptions = {
      error: { operation: 'Update Markers', toast: true } as ErrorOptions,
      successToast: { title: "Markers Updated", message: "Your Markers have been updated." } as ToastOptions,
    }
    const markers = this.features.filter(feature => featureIDs.includes(feature.id) && feature.type == 'marker')

    markers.forEach(feature => {
      Object.keys(updates).forEach(key => {
        const property = feature.properties.find(prop => prop.key === key)

        if (property) {
          property.value = updates[key]
        }
      })
    })

    this.updateFeaturesLocally(...markers)

    formData.append('feature_ids', JSON.stringify(featureIDs))

    // Add each update to the FormData, converting camelCase to snake_case where necessary
    Object.keys(updates).forEach(key => {
      const snakeKey = toSnakeCase(key) // Convert camelCase to snake_case
      const value = updates[key]

      if (key === 'image' && value instanceof File) {
        formData.append('file', value, value.name)
      } else {
        formData.append(`${snakeKey}_in`, value.toString())
      }
    })

    return this._requestService.update<{ response: string }>(url, formData, endpointOptions)
  }

  public updateGroupVisibilities(parent: Feature, features: Feature[]): Observable<Feature[]> {
    const url = `${environment.api}/feature/group/visibility/${parent.id}`
    const endpointOptions: EndpointOptions = {
      error: { operation: 'Update Visibility', toast: true } as ErrorOptions,
      successToast: { title: "Visibility Updated", message: "Your Features have been updated" } as ToastOptions,
    }

    return this._requestService.update(url, { visible: parent.visible }, endpointOptions).pipe(
      map(() => {
        this.updateVisibilityLocally(...features)
        return features
      })
    )
  }

  /** Creates Feature of type group. Optionally adds any children as part of the group. */
  public createFeatureGroup(options: CreateGroupOptions = {}): Observable<Feature> {
    const sceneID = this._sceneService.selectedSceneID
    const children = options.children ?? []
    const parentID: number = options?.parentID ?? this.getCommonAncestor(children)?.id
    const name: string = options?.name ?? 'New Group'
    const group = new Feature(sceneID, name, 'group', { parentID, children })

    return this.createFeature(group, { toast: options?.toast ?? true }).pipe(
      switchMap(result => {
        group.id = result.id

        if (children.length > 0) {
          const updateChildren$ = children.map(child => {
            child.parentID = group.id

            return this.updateFeature(child)
          })

          return forkJoin(updateChildren$).pipe(map(() => group))
        } else {
          return of(group)
        }
      })
    )
  }

  removeFeatureFromGroup(feature: Feature, options?: { newParentID: number }) {
    const parent = this.getFeature(feature.parentID)
    const children = this.features.filter(feature => feature.parentID == parent.id)
    const deleteParent = children.length == 1

    feature.parentID = options?.newParentID

    return this.updateFeature(feature).pipe(
      switchMap(feature => {
        if (deleteParent) return this.deleteFeature(parent, false).pipe(map(() => feature))
        else return of(feature)
      })
    )
  }

  public removeFeaturesFromGroup(group: Feature) {
    const url = `${environment.api}/feature/ungroup/${group.id}`
    const endpointOptions: EndpointOptions = {
      error: { operation: 'Remove Features From Group', toast: true } as ErrorOptions,
      successToast: { title: "Features Removed From Group", message: "Your Features have been updated." } as ToastOptions,
    }
    return this._requestService.update(url, {}, endpointOptions).pipe(
      tap(() => {
        let features = this.features
        features.forEach(feature => {
          if (feature.parentID == group.id) feature.parentID = group.parentID
        })
        features = features.filter(feature => feature.id != group.id)
        this.features = features
      })
    )
  }

  public updateLoadLocally(...updatedFeatures: Feature[]) {
    if (updatedFeatures?.length > 0) {
      const features = this.getFeatures()
      updatedFeatures.forEach(updatedFeature => {
        if (updatedFeature != null) {
          const featureIndex = features.findIndex(feature => feature.id == updatedFeature.id)

          features[featureIndex].unloaded = updatedFeature.unloaded
        }
      })

      this.features = features
    }
  }

  public updateVisibilityLocally(...updatedFeatures: Feature[]) {
    if (updatedFeatures?.length > 0) {
      const features = this.getFeatures()
      updatedFeatures.forEach(updatedFeature => {
        if (updatedFeature != null) {
          const featureIndex = features.findIndex(feature => feature.id == updatedFeature.id)

          features[featureIndex].visible = updatedFeature.visible
        }
      })

      this.features = features
    }
  }

  /** Attributes include description, customFields, and properties */
  public updateFeatureAttributesLocally(updatedFeature: Feature) {
    const features = this.getFeatures()
    const featureIndex = features.findIndex(feature => feature.id == updatedFeature.id)

    if (updatedFeature != null && featureIndex != -1) {
      this.features[featureIndex].description = updatedFeature.description
      this.features[featureIndex].customFields = updatedFeature.customFields
      this.features[featureIndex].properties = updatedFeature.properties
    }
  }

  /**
  * Adds to or updates the `features` list with new Features causing an emission from features$
  * @param updatedFeatures A list of type Feature[] that will replace features.
  **/
  public updateFeaturesLocally(...updatedFeatures: Feature[]) {
    if (updatedFeatures?.length > 0) {
      const features = this.getFeatures()
      updatedFeatures.forEach(updatedFeature => {
        if (updatedFeature != null) {
          updatedFeature = new Feature(updatedFeature.sceneID, updatedFeature.name, updatedFeature.type, updatedFeature)
          const featureIndex = features.findIndex(feature => feature.id == updatedFeature.id)

          if (featureIndex == -1) {
            features.push(updatedFeature)
          } else {
            features[featureIndex] = updatedFeature
          }

          if (updatedFeature.type == "line" || updatedFeature.type == "polygon") {
            let stringCoordinates = updatedFeature.properties.find(p => p.key == "coordinateString").value
            let coordinates = JSON.parse(stringCoordinates)
            this.featurePositions.set(updatedFeature.id, coordinates)
          } else {
            this.featurePositions.set(updatedFeature.id, updatedFeature.position)
          }
        }
      })

      this.features = features
    }
  }

  public removeFeatureLocally(...removedFeatures: Feature[]) {
    if (removedFeatures?.length > 0) {
      const features = this.getFeatures()

      removedFeatures.forEach(removedFeature => {
        const featureIndex = features.findIndex(feature => feature.id == removedFeature.id)

        if (featureIndex != -1) {
          features.splice(featureIndex, 1)
          this.featurePositions.delete(removedFeature.id)
        }
      })

      this.features = features
    }
  }

  public createFeatureProperty(property: FeatureProperty): Observable<FeatureProperty> {
    const url = `${environment.api}/feature/property/${property.featureID}`

    return this._http.post<{ id: number }>(url, property, { observe: 'body', responseType: 'json' }).pipe(
      catchError(this.handleError<{ id: number }>("Failed to create feature property.")),
      map(({ id: propertyID }) => {
        const feature = this.features.find(feature => feature.id == property.featureID)

        property = new FeatureProperty(property.type, property.key, property.value, { ...property, id: propertyID })

        if (feature.properties) {
          feature.properties.push(property)
        } else {
          feature.properties = [property]
        }

        this.updateFeaturesLocally(feature)

        this._toastService.toast({ title: "Feature Property Created", color: "green" })

        return property
      })
    )
  }

  public updateFeatureProperty(property: FeatureProperty, options = {} as Partial<UpdateFeatureOptions>) {
    const url = `${environment.api}/feature/property/${property.id}`

    options.toast = options?.toast ?? true
    options.color = options?.color ?? 'green'
    options.title = options?.title ?? property.key == "attachment" ? "Attachment Updated" : "Feature Property Updated"

    return this._http.put(url, property, httpOptions).pipe(
      catchError(this.handleError("Failed to update feature property.")),
      map(() => {
        const feature = this.features.find(f => f.id == property.featureID)
        const propertyIndex = feature.properties.findIndex(prop => prop.id == property.id)

        property = new FeatureProperty(property.type, property.key, property.value, property)


        if (propertyIndex != -1) {
          feature.properties[propertyIndex] = property
        }

        this.updateFeaturesLocally(feature)

        if (options.toast) {
          this._toastService.toast({ title: options.title, color: options.color })
        }

        return property
      }),
    )
  }

  public deleteFeatureProperty(property: FeatureProperty): Observable<FeatureProperty> {
    const url = `${environment.api}/feature/property/${property.id}`

    return this._http.delete(url, httpOptions).pipe(
      catchError(this.handleError("Failed to delete feature property.")),
      map(() => {
        const feature = this.features.find(f => f.id == property.featureID)
        const propertyIndex = feature.properties.findIndex(prop => prop.id == property.id)

        if (propertyIndex != -1) {
          feature.properties.splice(propertyIndex, 1)
        }

        this.updateFeaturesLocally(feature)
        this._toastService.toast({ title: property.key == "attachment" ? "Attachment Deleted" : "Feature Property Deleted", color: "green" })
        return property
      }))
  }

  public getFeaturesDetails(feature: Feature): Observable<{ description: string, customFields: FeatureCustomField[], attachments: FeatureProperty[] }> {
    const featureID = feature.id
    const endpointOptions: EndpointOptions = {
      error: { operation: 'Get Features Details', toast: true } as ErrorOptions,
    }

    return this._authenticationService.isAuthenticated$.pipe(
      switchMap(isAuthenticated => {
        if (isAuthenticated) {
          var url = `${environment.api}/feature/details/${featureID}`
        } else {
          var url = `${environment.api}/public/feature/details/${featureID}`
        }

        return this._requestService.get<{ description: string, customFields: FeatureCustomFieldResult[], attachments: FeaturePropertyResult[] }>(url, endpointOptions).pipe(
          map(({ description, customFields, attachments }) => {
            const cfs = customFields.map(customField => customFieldFromResult(customField))
            const atts = attachments.map(attachment => featurePropertyFromResult(attachment))
            return { description, customFields: cfs, attachments: atts }
          }),
          tap(({ description, customFields, attachments }) => {
            feature.description = description
            feature.customFields = customFields
            feature.properties.push(...attachments)

            this.updateFeatureAttributesLocally(feature)
          })
        )
      })
    )
  }

  getProjectsFeaturesCount(projectID: number, isAuthenticated: boolean = true): Observable<number> {
    const endpointOptions: EndpointOptions = {
      error: { operation: 'Get Projects Features Count', toast: true } as ErrorOptions,
      errorFunction: () => {
        sessionStorage.setItem('authRedirectOrigin', this._router.url)
        this._router.navigate(['login'])
      }
    }

    if (isAuthenticated) {
      var url = `${environment.api}/features/count/${projectID}`
    } else {
      var url = `${environment.api}/public/features/count/${projectID}`
    }

    return this._requestService.get<number>(url, endpointOptions)
  }

  public getFeaturesCustomFields(feature: Feature): Observable<FeatureCustomField[]> {
    const featureID = feature.id
    const endpointOptions: EndpointOptions = {
      error: { operation: 'Get Features Custom Fields', toast: true } as ErrorOptions,
    }

    return this._authenticationService.isAuthenticated$.pipe(
      switchMap(isAuthenticated => {
        if (isAuthenticated) {
          var url = `${environment.api}/feature/custom-field/${featureID}`
        } else {
          var url = `${environment.api}/public/feature/custom-field/${featureID}`
        }

        return this._requestService.get<FeatureCustomFieldResult[]>(url, endpointOptions).pipe(
          map(customFieldsResults => customFieldsResults.map(customField => customFieldFromResult(customField))),
          tap(customFields => {
            feature.customFields = customFields

            this.updateFeaturesLocally(feature)
          })
        )
      })
    )
  }

  public createCustomField(customField: FeatureCustomField): Observable<FeatureCustomField> {
    const featureID = customField.featureID
    const url = `${environment.api}/feature/custom-field/${featureID}`
    const endpointOptions: EndpointOptions = {
      error: { operation: 'Create Custom Field', toast: true } as ErrorOptions,
      successToast: { title: "Custom Field Created", message: "Your Custom Field has been created." } as ToastOptions,
    }

    return this._requestService.create<FeatureCustomField>(url, customField, endpointOptions).pipe(
      tap(customField => {
        const feature = this.features.find(({ id }) => id == featureID)

        if (feature.customFields) {
          feature.customFields.push(customField)
        } else {
          feature.customFields = [customField]
        }

        this.updateFeaturesLocally(feature)
      })
    )
  }

  public deleteCustomField(customField: FeatureCustomField): Observable<FeatureCustomField> {
    const url = `${environment.api}/feature/custom-field/${customField.id}`
    const endpointOptions: EndpointOptions = {
      error: { operation: 'Delete Custom Field', toast: true } as ErrorOptions,
      successToast: { title: "Custom Field Deleted", message: "Your Custom Field has been deleted." } as ToastOptions,
    }

    return this._requestService.delete<{ response: string }>(url, endpointOptions).pipe(
      map(() => {
        const feature = this.features.find(({ id }) => id == customField.featureID)
        const customFieldIndex = feature.customFields.findIndex(({ id }) => id == customField.id)
        if (customFieldIndex != -1) {
          feature.customFields.splice(customFieldIndex, 1)
        }
        this.updateFeaturesLocally(feature)
        return customField
      })
    )
  }

  public updateCustomField(customField: FeatureCustomField) {
    const url = `${environment.api}/feature/custom-field/${customField.id}`
    const endpointOptions: EndpointOptions = {
      error: { operation: 'Update Custom Field', toast: true } as ErrorOptions,
      successToast: { title: "Custom Field Updated", message: "Your Custom Field has been updated." } as ToastOptions,
    }

    return this._requestService.update<{ response: string }>(url, customField, endpointOptions).pipe(
      map(() => {
        const feature = this.features.find(({ id }) => id == customField.featureID)
        const customFieldIndex = feature.customFields.findIndex(({ id }) => id == customField.id)

        if (customFieldIndex != -1) {
          feature.customFields[customFieldIndex] = customField
        }

        this.updateFeaturesLocally(feature)

        return customField
      }),
    )
  }

  /**
   * Finds all the children of a Feature.
   * @param parent    Feature of type group.
   * @returns  Feature[] with all the children.
   */
  public getDescendants(parent: Feature): Feature[] {
    if (parent?.type != 'group') return []

    const descendants: Feature[] = []

    this.scenesFeatures.get(parent.sceneID).forEach(child => {
      if (child.parentID == parent.id) {
        if (child.type == 'group')
          descendants.push(...this.getDescendants(child))

        descendants.push(child)
      }
    })

    return descendants
  }

  public getChildren(parent: Feature): Feature[] {
    if (parent?.type != 'group') return []

    return this.scenesFeatures.get(parent.sceneID).filter(child => child.parentID == parent.id)
  }

  getParent(child: Feature) {
    if (child?.parentID != null) {
      return this.scenesFeatures.get(child.sceneID).find(parent => parent.id == child.parentID)
    }
  }

  getAncestors(feature: Feature): Feature[] {
    if (feature?.parentID != null) {
      const seenMap = new Map<number, Feature>()
      const ancestors: Feature[] = []
      let ancestor = this.getParent(feature)

      seenMap.set(feature.id, feature)

      if (ancestor != null) {
        do {
          if (seenMap.has(ancestor?.id)) {
            console.error('Grouped Features form a loop', feature)
            return ancestors
          }

          ancestors.push(ancestor)
          seenMap.set(ancestor.id, ancestor)

          ancestor = this.getParent(ancestor)
        } while (ancestor != null)
        return ancestors
      } else { // Feature has a parent, but we couldn't find it
        return []
      }
    } else { // Feature does not have ancestors
      return []
    }
  }

  public getCommonAncestor(features: Feature[]): Feature {
    const getDepth = (feature: Feature): number => {
      if (!feature) return 0
      let seenMap = new Map<number, boolean>()
      let depth = 0

      do {
        depth++
        seenMap.set(feature.id, true)
        feature = this.features.find(f => f.id == feature.parentID && feature.parentID)
        if (feature && seenMap.has(feature.id)) {
          console.error('Grouped Features form a loop', feature)
          return depth
        }
      } while (feature)
      return depth
    }

    let commonAncestors = []
    features.forEach((feature, ind) => {
      if (ind == 0) commonAncestors = this.getAncestors(feature)
      commonAncestors = this.getAncestors(feature).filter(anc => commonAncestors.includes(anc))
    })

    let commonAncestor: Feature = undefined
    commonAncestors.forEach(ancestor => {
      if (getDepth(ancestor) > getDepth(commonAncestor)) commonAncestor = ancestor
    })
    return commonAncestor
  }

  addAttachments(featureID: number, files: File[]) {
    const url = `${environment.api}/feature/attachments/${featureID}`
    const body = files.map(file => {
      const formData = new FormData()
      formData.append('featureID', featureID.toString())
      formData.append('file', file, file.name)
      return formData
    })
    const options: ManyCRUDOptions = {
      successToast: { title: `Attachment ${files.length > 1 ? 'Files' : 'File'} Uploaded` },
      error: { operation: "Add Attachments", toast: true },
      progressBar: { title: `Uploading Attachment ${files.length > 1 ? 'Files' : 'File'}`, autohide: false },
      simultaneous: false
    }

    return this._requestService.createMany<FeaturePropertyResult[]>(url, body, options).pipe(
      // Flattens observable array 
      map(result => [].concat.apply([], result.map(properties => properties.map(p => featurePropertyFromResult(p)))) as FeatureProperty[]),
      map(attachments => {
        const feature = this.features.find(feature => feature.id == featureID)

        if (!feature.properties) {
          feature.properties = []
        }

        feature.properties.push(...attachments)

        this.updateFeaturesLocally(feature)

        return attachments
      })
    )
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'Operation did not complete.', result?: T) {
    return (error: any): Observable<T> => {

      this._toastService.toast({ title: "Error", message: operation, color: "red" })

      // TODO: send the feature error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
