import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { booleanRuleTypes, numberRuleTypes, Rule, RuleOperator, SearchableProperty, textRuleTypes } from '@classes/Rules'
import { FeatureService } from '@services/feature.service'

@Component({
  selector: 'shared-edit-rule',
  templateUrl: './edit-rule.component.html',
  styleUrls: ['./edit-rule.component.css']
})
export class EditRuleComponent implements OnInit {
  @Input() rule: Rule
  @Input() searchableProperties: SearchableProperty[]
  @Output() edit = new EventEmitter<Rule>()

  public userInputType = 'text'
  public propertyListVisible = false
  public searchablePropertyName = ''
  public inputIsValid = true

  get operators(): readonly RuleOperator[] {
    switch (this.rule.searchableProperty.valueType) {
      case 'text': return textRuleTypes
      case 'number': return numberRuleTypes
      case 'boolean': return booleanRuleTypes
      default: return textRuleTypes
    }
  }

  constructor(public featureService: FeatureService) { }

  ngOnInit(): void {
    this.updateProperty(this.rule.searchableProperty)
  }

  private updateProperty(property: SearchableProperty) {
    this.searchablePropertyName = property.name
    this.rule.searchableProperty = property
    this.userInputType = property.valueType

    if (!this.operators.includes(this.rule.operator)) {
      this.rule.operator = this.operators[0]
    }

    if (this.userInputType == 'boolean' && this.rule.userInput != 'true' && this.rule.userInput != 'false') {
      this.rule.userInput = 'false'
    }
  }


  selectSearchableProperty(property: SearchableProperty) {
    this.updateProperty(property)
    this.emitRule()
  }

  showSearchablePropertyList() {
    this.propertyListVisible = true
  }

  checkIfInputIsValid() {
    this.inputIsValid = this.searchableProperties.some(p => p.name.toUpperCase() == this.searchablePropertyName.toUpperCase())
  }

  hideSearchablePropertyList() {
    this.propertyListVisible = false

    this.searchableProperties.forEach(property => {
      if (this.searchablePropertyName.toLowerCase() == property.name.toLowerCase()) {
        this.selectSearchableProperty(property)
      } else {
        this.checkIfInputIsValid()
      }
    })
  }

  onRuleTypeChange(value: string) {
    this.rule.operator = value as RuleOperator
    this.emitRule()
  }

  onUserInputChange(value: string) {
    this.rule.userInput = value
    this.emitRule()
  }

  onBooleanUserInputChange() {
    if (this.rule.userInput == 'false') this.rule.userInput = 'true'
    else this.rule.userInput = 'false'
    this.emitRule()
  }

  preventBlur(event: MouseEvent) {
    event.preventDefault()
  }

  private emitRule() {
    this.edit.emit({ ...this.rule })
  }
}