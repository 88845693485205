import { combineLatest, timer } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'

import { AfterContentChecked, ChangeDetectorRef, Component, ViewChild, ViewContainerRef } from '@angular/core'
import { Router } from '@angular/router'
import { AuthenticationService } from '@services/authentication.service'
import { ContextMenuService } from '@services/context-menu.service'
import { ModalService } from '@services/modal.service'
import { SubscriptionService } from '@services/subscription.service'
import { UserService } from '@services/user.service'

import { ContextMenuDirective } from './directives/context-menu.directive'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterContentChecked {
  @ViewChild(ContextMenuDirective, { static: true }) contextMenu!: ContextMenuDirective;

  get currentModule() { return this.authenticationService.currentModule }
  get currentUser$() { return this.userService.currentUser$ }
  get user$() { return this.authenticationService.user$ }
  bothUsers$ = combineLatest([this.user$, this.currentUser$]).pipe(
    map(([user, currentUser]) => {
      user.name = currentUser.name
      user.picture = currentUser.picture
      return user
    })
  )
  get isSafari() {
    const agent = navigator.userAgent
    return !agent.includes('Chromium') && !agent.includes('Chrome') && agent.includes('Safari')
  }

  public editing: boolean = false

  constructor(
    private _router: Router,
    private _subscriptionService: SubscriptionService,
    public authenticationService: AuthenticationService,
    public cdr: ChangeDetectorRef,
    public contextMenuService: ContextMenuService,
    public modalService: ModalService,
    public userService: UserService,
    public viewContainerRef: ViewContainerRef,
  ) {
    timer(0, 30 * 60 * 1000).pipe( // Once immediately and repeats every 30 minutes
      switchMap(() => this.authenticationService.createAccessToken())
    ).subscribe(token => localStorage.setItem('accessToken', token))

    /**
     * Disabling image bitmapping improves performance 
     * & reduces crashing experienced on iOS devices
     */
    if (this.isSafari) window.createImageBitmap = undefined

    // Give the ModalService reference to the AppComponent
    this.modalService.initialize(this.viewContainerRef)
  }

  ngAfterViewInit(): void {
    this.contextMenuService.container = this.contextMenu.viewContainerRef
  }

  resetPassword() {
    this._subscriptionService.resetPassword().subscribe(
      ({ response }) => {
        window.location.href = response
      })
  }

  logout() {
    this.authenticationService.logout()
  }

  closeContextMenu() {
    this.contextMenuService.closeContext()
  }

  isValidEmail(email: string): boolean {
    // ^          - Asserts the start of the string.
    // [^\s @]+   - Matches one or more characters that are not whitespace(\s) or the '@' symbol.
    // @          - Matches the '@' symbol.
    // [^\s @]+   - Matches one or more characters that are not whitespace(\s) or the '@' symbol.
    // \.         - Escapes the '.' symbol and matches a literal dot.
    // [^\s @]+   - Matches one or more characters that are not whitespace(\s) or the '@' symbol.
    // $          - Asserts the end of the string.
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  updateUser(user: any) {
    // if (this.isValidEmail(user.email)) {
    this.userService.updateUser(user).subscribe()
    // }
  }

  navigateToFeedback() {
    // Dismiss any active modals to avoid aria-hidden errors
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }

    // Get the current URL for the `redirect` query parameter
    const currentUrl = this._router.url

    // Navigate to the feedback page with the current URL as a redirect parameter
    this._router.navigate(['/feedback'], {
      queryParams: { redirect: currentUrl }
    })
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges()
  }
}