import * as Mapbox from 'mapbox-gl'
import { BehaviorSubject, EMPTY, fromEvent, merge, Observable, Subscription, timer } from 'rxjs'
import { buffer, debounceTime, filter, map, mergeAll, skip, switchMap, take, tap, windowCount } from 'rxjs/operators'
import * as THREE from 'three'

import { Coordinates, getMapCoordsFromScenePosition, getScenePositionFromMapCoords, MapConfiguration } from '@utils/MapboxUtils'
import { isMesh } from '@utils/ThreeJS'

import { Feature } from './Feature'
import { SceneManager } from './SceneManager'

export type IntersectedObject = {
  /** Scene position in terms of lng, lat, alt */
  coordinates?: Coordinates
  /** Specific face of an intersected model */
  face?: THREE.Face
  event?: MouseEvent | TouchEvent | Mapbox.MapMouseEvent | Mapbox.MapTouchEvent
  mapFeature?: Mapbox.GeoJSONFeature | Feature
  model?: THREE.Group
  /** X,Y position of the mouse on the screen */
  mousePosition?: THREE.Vector2
  /** Scene position in terms of x,y,z */
  point?: THREE.Vector3
}

const hoverSubject = new BehaviorSubject<IntersectedObject>(undefined)
const intersectionSubject = new BehaviorSubject<IntersectedObject>(undefined)
// const longSelectSubject = new BehaviorSubject<number>(undefined)
const selectSubject = new BehaviorSubject<IntersectedObject>(undefined)
const virtualTourCursorSubject = new BehaviorSubject<IntersectedObject>(undefined)
export const hover$ = hoverSubject.pipe(skip(1))
export const intersectionPoint$ = intersectionSubject.pipe(skip(1))
// export const longSelect$ = longSelectSubject.pipe(skip(1))
export const select$ = selectSubject.pipe(skip(1))
export const virtualTourCursor$ = virtualTourCursorSubject.pipe(skip(1))

const moveSubject = new BehaviorSubject<MouseEvent | TouchEvent | Mapbox.MapMouseEvent | Mapbox.MapTouchEvent>(undefined)
const clickSubject = new BehaviorSubject<MouseEvent | TouchEvent | Mapbox.MapMouseEvent | Mapbox.MapTouchEvent>(undefined)
const longclickSubject = new BehaviorSubject<MouseEvent | TouchEvent | Mapbox.MapMouseEvent | Mapbox.MapTouchEvent>(undefined)
const doubleclickSubject = new BehaviorSubject<MouseEvent | TouchEvent | Mapbox.MapMouseEvent | Mapbox.MapTouchEvent>(undefined)
const dragSubject = new BehaviorSubject<MouseEvent | TouchEvent | Mapbox.MapMouseEvent | Mapbox.MapTouchEvent>(undefined)
const dragendSubject = new BehaviorSubject<MouseEvent | TouchEvent | Mapbox.MapMouseEvent | Mapbox.MapTouchEvent>(undefined)
export const move$ = moveSubject.pipe(skip(1))
export const click$ = clickSubject.pipe(skip(1))
export const longclick$ = longclickSubject.pipe(skip(1))
export const doubleclick$ = doubleclickSubject.pipe(skip(1))
export const drag$ = dragSubject.pipe(skip(1))
export const dragend$ = dragendSubject.pipe(skip(1))

export class Raycaster {
  private _mousePos = new THREE.Vector2()
  public raycaster: THREE.Raycaster = new THREE.Raycaster()
  public state: 'none' | 'down' | 'longDown' | 'doubleDown' | 'move' | 'drag' = 'none'
  public subscriptions: Subscription[] = []

  get meshes() {
    const exceptionalTypes = ['Controller', 'GridHelper', 'AxesHelper', '360 Image']
    const meshes: THREE.Mesh[] = []

    this._sceneManager.renderedScene.children
      .filter(child => !exceptionalTypes.includes(child.userData.type))
      .forEach(child => child.traverseVisible(desc => { if (isMesh(desc)) meshes.push(desc) }))

    return meshes
  }

  get pointerMove$(): Observable<Mapbox.MapMouseEvent | PointerEvent> {
    if (this._map)
      return fromEvent<Mapbox.MapMouseEvent>(this._map, 'mousemove')
        .pipe(tap(e => this._updateMousePosition(e)))
    else
      return fromEvent<PointerEvent>(this._canvas, 'pointermove').pipe(
        filter(e => e?.pointerType != 'touch'),
        tap(e => this._updateMousePosition(e)))
  }

  get pointerDown$(): Observable<Mapbox.MapMouseEvent | PointerEvent> {
    if (this._map)
      return fromEvent<Mapbox.MapMouseEvent>(this._map, 'mousedown')
        .pipe(tap(e => this._updateMousePosition(e)))
    else
      return fromEvent<PointerEvent>(this._canvas, 'pointerdown').pipe(
        tap(e => this._updateMousePosition(e)))
  }

  get pointerUp$(): Observable<Mapbox.MapMouseEvent | PointerEvent> {
    if (this._map)
      return fromEvent<Mapbox.MapMouseEvent>(this._map, 'mouseup')
        .pipe(tap(e => this._updateMousePosition(e)))
    else
      return fromEvent<PointerEvent>(this._canvas, 'pointerup').pipe(
        tap(e => this._updateMousePosition(e)))
  }

  /** A new touch point */
  get touchStart$(): Observable<Mapbox.MapTouchEvent | TouchEvent> {
    if (this._map)
      return fromEvent<Mapbox.MapTouchEvent>(this._map, 'touchstart')
        .pipe(tap(e => this._updateMousePosition(e)))
    else
      return fromEvent<TouchEvent>(this._canvas, 'touchstart')
        .pipe(tap(e => this._updateMousePosition(e)))
  }

  /** A touch point is moving */
  get touchMove$(): Observable<Mapbox.MapTouchEvent | TouchEvent> {
    if (this._map)
      return fromEvent<Mapbox.MapTouchEvent>(this._map, 'touchmove')
        .pipe(tap(e => this._updateMousePosition(e)))
    else
      return fromEvent<TouchEvent>(this._canvas, 'touchmove')
        .pipe(tap(e => this._updateMousePosition(e)))
  }

  /** A touch point was disrupted */
  get touchEnd$(): Observable<Mapbox.MapTouchEvent | TouchEvent> {
    if (this._map) return fromEvent<Mapbox.MapTouchEvent>(this._map, 'touchend')
    else return fromEvent<TouchEvent>(this._canvas, 'touchend')
  }

  get mapDrag$(): Observable<MouseEvent> {
    if (this._map) return fromEvent<Mapbox.MapMouseEvent>(this._map, 'drag').pipe(
      map(e => e.originalEvent),
      tap(e => this._updateMousePosition(e)))
    return EMPTY
  }

  get mapDragend$(): Observable<MouseEvent> {
    if (this._map) return fromEvent<Mapbox.MapMouseEvent>(this._map, 'dragend')
      .pipe(map(e => e.originalEvent))
    return EMPTY
  }

  get mouseWheel$() {
    return fromEvent<WheelEvent>(this._canvas, 'wheel')
  }

  private get _center() { return this._config?.center }
  private get _map() { return this._config?.map }

  constructor(
    private _sceneManager: SceneManager,
    private _camera: THREE.Camera,
    private _canvas: HTMLCanvasElement,
    private _config?: MapConfiguration,
  ) {
    // @ts-ignore
    this.raycaster.firstHitOnly = true
    this.raycaster.params.Line.threshold = 0.01
    this.raycaster.params.Points.threshold = 1e-2

    this.subscriptions.push(
      this.pointerDown$.pipe(
        switchMap(() => {
          this.state = 'down'
          return timer(500).pipe(take(1))
        })
      ).subscribe(e => {
        if (this.state == 'down') {
          this.state = 'longDown'
        }
      }),

      this.pointerDown$.pipe(
        buffer(this.pointerDown$.pipe(debounceTime(500))),
        filter(clicks => clicks.length == 2)
      ).subscribe(clicks => {
        this.state = 'doubleDown'
        doubleclickSubject.next(clicks[0])
      }),

      this.pointerMove$.pipe(
        windowCount(5),
        map(win => win.pipe(skip(4))), // Skip 4 of 5 emissions
        mergeAll()
      ).subscribe(e => {
        if (this.state == 'none' || this.state == 'move') {
          this.state = 'move'
          moveSubject.next(e)
        } else if (['down', 'doubleDown', 'longDown', 'drag'].includes(this.state)) {
          this.state = 'drag'
          dragSubject.next(e)
        }
      }),

      this.pointerUp$.subscribe((e) => {
        // TODO: Utilize middle and right clicks
        if (e instanceof PointerEvent) {
          var isLeftClick = e?.button == 0
          // var isMiddleClick = e?.button == 1
          // var isRightClick = e?.button == 2
        } else {
          var isLeftClick = e?.originalEvent?.button == 0
          // var isMiddleClick = e?.originalEvent?.button == 1
          // var isRightClick = e?.originalEvent?.button == 2
        }

        if (isLeftClick) {
          if (this.state == 'down') clickSubject.next(e)
          if (this.state == 'longDown') longclickSubject.next(e)
          else if (this.state == 'drag') dragendSubject.next(e)
        }

        this.state = 'none'
      }),

      this.touchMove$.subscribe(e => {
        if (['down', 'doubleDown', 'longDown', 'drag'].includes(this.state)) {
          this.state = 'drag'
          dragSubject.next(e)
        }
      }),

      this.mapDrag$.subscribe(e => {
        this.state = 'drag'
        dragSubject.next(e)
      }),

      this.mapDragend$.subscribe(e => {
        this.state = 'none'
        dragendSubject.next(e)
      }),

      // TODO: Use long click for something
      merge(click$, longclick$).subscribe(e => {
        const intersection = this.getIntersection(e)
        // 1. Emit to select subject
        selectSubject.next(intersection)

        const previousIntersection = intersectionSubject.value
        const prevPoint = previousIntersection?.point
        const isPoint = intersection.point
        const payload = { point: intersection.point, group: intersection.model, mapFeature: intersection.mapFeature }

        // 2. Emit to measurement intersection subject
        if (isPoint && prevPoint && !prevPoint.equals(isPoint)) intersectionSubject.next(payload)
        else if (isPoint) intersectionSubject.next(payload)
      }),
      this.pointerMove$.subscribe(e => {
        // This ensures the cursor plate will move even while dragging.
        const current = this.getIntersection(e)
        /* Virtual Tour Cursor */
        virtualTourCursorSubject.next(current)

      }),
      move$.subscribe(e => {
        const previous = hoverSubject.value
        const current = this.getIntersection(e)

        current.point = current.point ?? new THREE.Vector3(current.coordinates?.lng, current.coordinates?.lat, current.coordinates?.alt)

        const featureTypeChanged = current?.model && previous?.mapFeature || current?.mapFeature && previous?.model
        const modelChanged = current?.model?.id != previous?.model?.id && current.mapFeature === undefined
        const mapFeatureChanged = current?.mapFeature?.id != previous?.mapFeature?.id

        if (featureTypeChanged || modelChanged || mapFeatureChanged) {
          hoverSubject.next(current)
        }

      })
    )
  }

  public getIntersection(event: MouseEvent | TouchEvent | Mapbox.MapMouseEvent | Mapbox.MapTouchEvent) {
    const intersections = this._intersect(this.meshes)
    const intersection: IntersectedObject = {}

    // Finds intersection information about the closest model that is interactable
    for (const { face, object: model, point } of intersections) {
      const parent = this._getParentModel(model)

      if (!parent.userData.ignoreRaycaster) {
        intersection.face = face
        intersection.model = parent
        intersection.point = point

        break
      }
    }

    if (this._map && !this._sceneManager.modelService.modelIsSelected) {
      const mapEvent = event as Mapbox.MapMouseEvent & { target: Mapbox.Map }
      const lngLat = mapEvent.lngLat

      if (this._map.getTerrain()) {
        var alt = this._map.queryTerrainElevation(lngLat)
      } else {
        var alt = 0
      }

      intersection.coordinates = { lng: lngLat.lng, lat: lngLat.lat, alt }

      if (intersection.point) { /* If there is an intersection with a model, we need to convert the scene position to coordinates */
        intersection.coordinates = getMapCoordsFromScenePosition(intersection.point, this._center)
      } else { /* If there is no intersection, then we need to get calculate a scene position from the intersection with the map */
        intersection.point = getScenePositionFromMapCoords(this._center, lngLat.lng, lngLat.lat, alt)
      }

      const queriedFeatures = this._map.queryRenderedFeatures(mapEvent.point).filter(f => f.properties.origin == 'karta')

      if (queriedFeatures.length > 0) {
        intersection.mapFeature = queriedFeatures[0]
      } else {
        let features = this._sceneManager.featureService.features.filter(f => f.type == 'rasterImage')
        if (features.length > 0) {
          for (const feature of features) {
            let coordinateString = feature.properties.find(p => p.key == "coordinateString")

            const coordinates: [number, number][] = JSON.parse(coordinateString.value)
            const bounds = new Mapbox.LngLatBounds(coordinates[0], coordinates[0])

            for (let i = 1; i < coordinates.length; i++) {
              bounds.extend(coordinates[i])
            }

            if (bounds.contains(mapEvent.lngLat)) {
              intersection.mapFeature = feature
            }
          }
        } else {
          intersection.mapFeature = undefined
        }
      }
    }

    intersection.mousePosition = this._mousePos.clone()
    intersection.event = event

    return intersection
  }

  private _updateMousePosition(event: PointerEvent | MouseEvent | TouchEvent | Mapbox.MapMouseEvent | Mapbox.MapTouchEvent) {
    const isMapboxEvent = event['point'] !== undefined
    let x, y
    let offsetLeft = 0
    let offsetTop = 0

    if (!this._map) {
      const parent = this._canvas.parentElement.parentElement
      offsetLeft = parent.offsetLeft
      offsetTop = parent.offsetTop
    }

    // Get x, y position of event
    if (event instanceof PointerEvent || event instanceof MouseEvent) {
      x = event.x
      y = event.y
    } else if (isMapboxEvent) {
      const e = event as Mapbox.MapMouseEvent
      x = e.point.x
      y = e.point.y
    } else if (event instanceof TouchEvent) {
      const lastTouch = event.touches[event.touches.length - 1]

      x = lastTouch.clientX
      y = lastTouch.clientY
    }

    this._mousePos.x = ((x - offsetLeft) / this._canvas.clientWidth) * 2 - 1
    this._mousePos.y = - ((y - offsetTop) / this._canvas.clientHeight) * 2 + 1
  }

  private _updateOriginAndDirection() {
    this.raycaster.setFromCamera(this._mousePos, this._camera)
    if (this._map) {
      var camInverseProjection = new THREE.Matrix4().copy(this._camera.projectionMatrix).invert()
      const cameraPosition = new THREE.Vector3().applyMatrix4(camInverseProjection)
      const mousePosition = new THREE.Vector3(this._mousePos.x, this._mousePos.y, 1).applyMatrix4(camInverseProjection)
      const viewDirection = mousePosition.clone().sub(cameraPosition).normalize()
      this.raycaster.set(cameraPosition, viewDirection)
    }
  }

  private _intersect(meshes: THREE.Mesh[]): THREE.Intersection[] {
    this._updateOriginAndDirection()
    let intersections = this.raycaster.intersectObjects(meshes)
    intersections.sort((a, b) => b.object.renderOrder - a.object.renderOrder)

    return intersections.length > 0 ? intersections : []
  }

  /** @returns A THREE.Group which is the direct child of the scene */
  private _getParentModel(model: THREE.Object3D) {
    while (model.parent.parent !== null && model.parent.type != 'ControllerExteriorGroup') // Ignores the transform controls group
      model = model.parent

    return model as THREE.Group
  }

  /**
   * This function transforms the 2D mouse coordinates into a 3D point in the scene,
   * using a given distance from the camera if the mouse is not over a mesh.
   * 
   * @returns {THREE.Vector3} The projected 3D position.
   */
  getMouse3DPosition(x: number, y: number, projectionDepth: number) {
    const mouse3D = new THREE.Vector3(x, y, 0.5) // Midway into the scene
    mouse3D.unproject(this._camera) // Transform to 3D

    // Direction from camera to mouse position
    const direction = mouse3D.sub(this._camera.position).normalize()

    // Projected point at the specified depth
    return this._camera.position.clone().add(direction.multiplyScalar(projectionDepth))
  }

  dispose() {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }
}