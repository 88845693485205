import { Component, OnInit } from '@angular/core'
import { FeatureService } from '@services/feature.service'
import { VecktaService } from '@services/veckta.service'

@Component({
  selector: 'viewer-veckta-view',
  templateUrl: './veckta-view.component.html',
  styleUrls: ['./veckta-view.component.css']
})
export class VecktaViewComponent implements OnInit {

  public projects = []
  openIndex: number | null = null

  constructor(
    public featureService: FeatureService,
    public vecktaService: VecktaService
  ) { }

  ngOnInit() {
    this.projects = this.vecktaService.projects
    this.vecktaService.vecktaProjectID$.subscribe(projectID => {
      const index = this.projects.findIndex(project => project.id == projectID)
      this.setIndex(index)
    })
  }

  setIndex(i: number) {
    if (this.openIndex === i) {
      // The user clicked the same panel that's already open, so close it.
      this.openIndex = -1
    } else {
      // Open the clicked panel.
      this.openIndex = i
    }
  }
}