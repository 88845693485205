import * as saveAs from 'file-saver'
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs'
import { catchError, distinctUntilKeyChanged, filter, map, retry, skip, switchMap, take } from 'rxjs/operators'
import { environment } from 'src/environments/environment'

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Connection, ConnectionProperty, IConnection, URLObject } from '@classes/Connection'
import { ConnectionResponse } from '@classes/ConnectionResponse'
import { Feature } from '@classes/Feature'
import { FileReference } from '@classes/FileReference'
import { EndpointOptions, RequestService } from '@services/request.service'
import { clone } from '@utils/Objects'

import { featureFromResult, FeatureResult } from './feature.service'
import { FileReferenceService } from './file-reference.service'
import { selectedScene$ } from './scene.service'
import { ToastService } from './toast.service'

const connectionsSubject = new BehaviorSubject<Connection[]>([])
const selectedConnectionSubject = new BehaviorSubject<Connection | undefined>(undefined)
const connectionResponseSubject = new BehaviorSubject<ConnectionResponse>(undefined)
const scenesConnectionsSource = new BehaviorSubject<Map<number, Connection[]>>(new Map())
export const connections$ = connectionsSubject.pipe()
export const selectedConnection$ = selectedConnectionSubject.asObservable()
export const connectionResponse$ = connectionResponseSubject.asObservable()
export const scenesConnections$ = scenesConnectionsSource.pipe(skip(1))

const httpOptions: HttpOptions = {
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
}

type HttpOptions = {
  headers?: { [header: string]: string | string[] }
  params?: { [param: string]: string | string[] }
}

type UpdateOptions = {
  toast: boolean,
  toastMessage: string,
  undoable: boolean
}

type ConnectionError = { title: string, error: string }

export type ProjectConnectionsResults = {
  features: FeatureResult[]
  errorMessages: ConnectionError[]
}

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {
  public showJSONPicker: boolean = false
  public selectionCallback: (key: string, value: any, path: string) => any

  private _urlStatusSource = new BehaviorSubject<'good' | 'bad' | 'loading'>('loading')
  public urlStatus$ = this._urlStatusSource.asObservable()
  get urlStatus() { return this._urlStatusSource.getValue() }
  set urlStatus(status: 'good' | 'bad' | 'loading') { this._urlStatusSource.next(status) }

  private _parsedURLSource = new BehaviorSubject<URLObject>(undefined)
  public parsedURL$ = this._parsedURLSource.asObservable()
  get parsedURL() { return this._parsedURLSource.getValue() }
  set parsedURL(url: URLObject) { this._parsedURLSource.next(url) }

  get scenesConnections() {
    return scenesConnectionsSource.getValue()
  }

  set scenesConnections(scenesConnections: Map<number, Connection[]>) {
    scenesConnectionsSource.next(scenesConnections)
  }

  public addScenesConnectionsEntry(sceneID: number) {
    const scenesConnections = this.scenesConnections
    scenesConnections.set(sceneID, [])
    this.scenesConnections = scenesConnections
  }

  get selectedConnection(): Connection {
    const connection = clone(selectedConnectionSubject.getValue())
    if (connection) return new Connection(connection.sceneID, connection)
    else return undefined
  }
  set selectedConnection(connection: Connection | undefined) {
    if (connection === undefined || this.connections.some(c => c.id == connection.id)) {
      selectedConnectionSubject.next(connection)
      this.parsedURL = (connection === undefined) ? undefined : JSON.parse(connection.url)
    }
  }

  /** @returns A deep copy of the most recently emitted connections. */
  get connections(): Connection[] {
    const connections = clone(connectionsSubject.getValue())
    return connections.map(c => new Connection(c.sceneID, c))
  }
  set connections(connections: Connection[]) {
    if (this.selectedConnection) {
      const selectedConnection = connections.find(c => c.id == this.selectedConnection.id)
      this.selectedConnection = selectedConnection
    }

    connectionsSubject.next(connections)
  }

  get connectionResponse() {
    let connectionResponse = clone(connectionResponseSubject.getValue())
    return new ConnectionResponse(connectionResponse.data, connectionResponse)
  }
  set connectionResponse(response: ConnectionResponse) { connectionResponseSubject.next(response) }

  constructor(
    private _fileReferenceService: FileReferenceService,
    private _http: HttpClient,
    private _requestService: RequestService,
    private _toastService: ToastService,
  ) {
    selectedScene$.pipe(
      distinctUntilKeyChanged('id'),
      map(scene => this.scenesConnections.get(scene.id)),
      filter(connections => connections != null)
    ).subscribe(connections => this.connections = connections)
  }

  public getProjectsConnectionFeatures(projectID: number, isAuthenticated = false): Observable<Feature[]> {
    const headers = { 'Cache-control': 'no-cache' }

    if (isAuthenticated) {
      var url = `${environment.api}/connections/features/${projectID}`
    } else {
      var url = `${environment.api}/public/connections/features/${projectID}`
    }

    return this._http.get<ProjectConnectionsResults>(url, { headers: headers }).pipe(
      retry(1),
      catchError((response, caught) => {
        this._toastService.toast({ title: response.error.title, message: response.error.message, color: 'red' })
        return EMPTY
      }),
      map(({ features, errorMessages }) => {
        errorMessages.forEach(({ title, error }) =>
          this._toastService.toast({ title, message: error, color: 'yellow' })
        )

        return features.map(feature => featureFromResult(feature))
      })
    )
  }

  public setSelectedConnectionByID(connectionID: number) {
    this.selectedConnection = this.connections.find(c => c.id == connectionID)
  }

  /**
  * Updates the state of the local list of connections and selectedConnection
  * after every call to create, delete, or update a connection.
  */
  public editConnectionLocally(connection: Connection, type: 'create' | 'delete' | 'update') {
    const updateConnectionsLocally = (updatedConnections: Connection[]) => this.connections = updatedConnections
    const connections = this.connections
    const connectionIndex = connections.findIndex(c => c.id == connection.id)

    if (type == 'create') connections.push(connection)
    else if (type == 'delete') {
      if (connectionIndex != -1) {
        connections.splice(connectionIndex, 1)
        if (connection?.id == this?.selectedConnection?.id) this.selectedConnection = undefined
      } else console.warn('Tried to remove unknown connection.')
    } else if (type == 'update') {
      if (connectionIndex != -1) {
        connections[connectionIndex] = connection
        if (connection?.id == this?.selectedConnection?.id) this.selectedConnection = connection
      } else console.warn('Tried to update unknown connection.')
    }

    updateConnectionsLocally(connections)
  }

  /**
  * Updates the state of the local list of connections and selectedConnection
  * after every call to create, delete, or update a property.
  */
  public editPropertyLocally(property: ConnectionProperty, type: 'create' | 'delete' | 'update') {
    if (!property?.connectionID) throw Error('Could not locally edit ConnectionProperty without connectionID.')

    const connection = this.connections.find(c => c.id == property.connectionID)
    const propertyIndex = connection.properties.findIndex(p => p.id == property.id)

    if (type == 'create') connection.properties.push(property)
    else if (type == 'delete') {
      if (propertyIndex != -1) connection.properties.splice(propertyIndex, 1)
      else console.warn('Tried to remove unknown connection property.')
    } else if (type == 'update') {
      if (propertyIndex != -1) connection.properties[propertyIndex] = property
      else console.warn('Tried to update unknown connection property.')
    }

    this.editConnectionLocally(connection, 'update')
  }

  public createConnection(connection: IConnection, imageFile?: File): Observable<Connection> {
    const url = `${environment.api}/connection/${connection.sceneID}`
    const formData: FormData = new FormData()
    const options: EndpointOptions = {
      successToast: { title: "Connection Created" },
      error: { operation: "Create Connection", toast: true }
    }

    formData.append('connection', JSON.stringify(connection))
    if (imageFile) formData.append('file', imageFile, imageFile.name)

    return this._requestService.create<IConnection>(url, formData, options).pipe(
      map(result => {
        const connection = new Connection(result.sceneID, result)

        this.editConnectionLocally(connection, 'create')

        return connection
      })
    )
  }

  public deleteConnection(connection: Connection): Observable<Connection> {
    const url = `${environment.api}/connection/${connection.id}`

    return this._http.delete(url).pipe(
      catchError(this.handleError("Failed to delete connection.")),
      map(() => {
        this.editConnectionLocally(connection, 'delete')
        this._toastService.toast({ title: "Connection Deleted", color: "green" })
        return connection
      })
    )
  }

  public updateConnection(connection: Connection, options: Partial<UpdateOptions> = {}) {
    const url = `${environment.api}/connection/${connection.id}`

    return this._http.put(url, connection, httpOptions).pipe(
      catchError(this.handleError("Failed to update connection.")),
      map(() => {
        this.editConnectionLocally(connection, 'update')
        if (options.toast) this._toastService.toast({ title: options.toastMessage ?? 'Connection Updated', color: "green" })
        return connection
      }),
    )
  }

  public createConnectionProperty(property: ConnectionProperty, toastMsg = "Connection Property Created"): Observable<ConnectionProperty> {
    const url = `${environment.api}/connection/property/${property.connectionID}`

    return this._http.post<{ id: number }>(url, property, { observe: 'body', responseType: 'json' }).pipe(
      catchError(this.handleError<{ id: number }>("Failed to create connection property.")),
      map(results => {
        property.id = results.id

        this.editPropertyLocally(property, 'create')
        if (toastMsg) this._toastService.toast({ title: toastMsg, color: "green" })

        return property
      })
    )
  }

  public updateConnectionProperty(property: ConnectionProperty, options: Partial<UpdateOptions> = {}) {
    const url = `${environment.api}/connection/property/${property.id}`

    return this._http.put(url, property, httpOptions).pipe(
      catchError(this.handleError("Failed to update connection property.")),
      map(() => {
        this.editPropertyLocally(property, 'update')
        if (options?.toast) this._toastService.toast({ title: options.toastMessage ?? 'Connection Property Updated', color: "green" })
        return property
      })
    )
  }

  public deleteConnectionProperty(property: ConnectionProperty, toastMsg = "Connection Property Deleted"): Observable<ConnectionProperty> {
    const url = `${environment.api}/connection/property/${property.id}`

    return this._http.delete(url, httpOptions).pipe(
      catchError(this.handleError("Failed to delete connection property.")),
      map(() => {
        this.editPropertyLocally(property, 'delete')
        if (toastMsg) this._toastService.toast({ title: toastMsg, color: "green" })
        return property
      })
    )
  }

  public createMarkerImage(connectionID: number, file: File) {
    let image = new Image
    image.src = window.URL.createObjectURL(file)
    image.onload = async () => {
      if (image.width > 1024 || image.height > 1024) file = await this._fileReferenceService.resizeImage(image, file)

      this._fileReferenceService.createFileReferences([file], { title: 'Uploading File', label: 'image' }).pipe(
        switchMap(([fileReference]) => {
          const property = new ConnectionProperty('image', 'file', file.name, { connectionID, fileReference })

          return this.createConnectionProperty(property)
        })
      ).subscribe()
    }
  }

  public updateMarkerImage(property: ConnectionProperty, icon: File) {
    const fileReference = property.fileReference

    let image = new Image
    image.src = window.URL.createObjectURL(icon)
    image.onload = async () => {
      if (image.width > 1024 || image.height > 1024) icon = await this._fileReferenceService.resizeImage(image, icon)

      fileReference.file = icon
      fileReference.label = 'image'

      this._fileReferenceService.updateFileReference(fileReference).pipe(
        catchError(this.handleError("Failed to update Connection property file.")),
        map((fileReference: FileReference) => {
          property.fileReference = fileReference

          this.editPropertyLocally(property, 'update')
          this._toastService.toast({ title: "File Updated", color: "green" })
        })
      ).subscribe()
    }
  }

  /* Makes request to Connection's API */
  public tryConnection(url: string, headers: any, parameters: any, options: HttpOptions = {}) {
    const apiPath = `${environment.api}/connections/call`
    const body = { url, headers, parameters }

    options.headers = { ...httpOptions.headers, ...options.headers }

    return this._http.put(apiPath, body, options)
  }

  // VECKTA Mode
  public tryVecktaAPI(community: string) {
    const apiPath = `${environment.api}/public/connections/veckta`

    return this._http.put(apiPath, { community: community })
  }

  public downloadAttachment(url: string, filename: string, headers: { [header: string]: string | string[] } = {}) {
    this._fileReferenceService.createBlob(url, headers)
      .subscribe(
        (file) => saveAs(file, filename),
        (error) => this._toastService.toast({
          message: 'Could not download attachment',
          title: 'No file available',
          color: 'red'
        })
      )
  }

  public setConnectionDataPickerCallback(selectionCallback: (key: string, value: any, path: string) => any) {
    this.selectionCallback = selectionCallback
  }

  public clearConnectionDataPickerCallback() {
    this.selectionCallback = undefined
  }

  public extractParams(url: string): { baseURL: string, params: [string, string][] } {
    const [baseURL, parameters] = url.split('?')
    const params = parameters.split('&').map(param => param.split('=')) as [string, string][]

    return { baseURL, params }
  }

  /**
  * Handle Http operation that failed.
  * Let the app continue.
  * @param operation - name of the operation that failed
  * @param result - optional value to return as the observable result
  */
  private handleError<T>(operation = 'Operation did not complete.', result?: T) {
    return (error: any): Observable<T> => {

      this._toastService.toast({ title: "Error", message: operation, color: "red" })

      // TODO: send the feature error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}