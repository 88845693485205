<div class="scroll-container p-2">
  <div *ngIf="projects?.['error'] != null" class="pt-4 px-3 text-center text-muted lead">
    No Projects at this time.<br>Check back later!
  </div>

  <div class="accordion" id="projectAccordion">
    <div class="accordion-item" *ngFor="let project of projects; let i = index">
      <h2 class="accordion-header" id="heading{{i}}">
        <button class="accordion-button" type="button" (click)="setIndex(i)" [class.collapsed]="openIndex !== i"
          [attr.aria-expanded]="openIndex === i">
          {{ project['Project Name'] }}
        </button>
      </h2>
      <div [id]="'collapse' + i" class="accordion-collapse collapse" [class.show]="openIndex === i"
        [attr.aria-labelledby]="'heading' + i" data-bs-parent="#projectAccordion">
        <div class="accordion-body">
          <p><strong>Name:</strong> {{ project['Project Name'] }}</p>
          <p><strong>Estimated Completion Date:</strong> {{ project['Estimated Completion Date'] | date }}</p>
          <p><strong>Current Status:</strong> {{ project.Status }}</p>
          <p><strong>Description:</strong> {{ project['Project Description/Use Case'] }}</p>
          <p><strong>Location:</strong> {{ project.Location }}</p>
          <p title="Size and capacity details of the project">
            <strong>Project Size:</strong>
            {{ project['Project Size']}}
          </p>
          <p><strong>Financials:</strong></p>
          <ul>
            <p><strong>Capital Expenditure ($):</strong> {{ project.CapEx | currency }}</p>
            <p><strong>Lifetime Savings ($):</strong> {{ project['Lifetime Savings'] | currency }}</p>
            <p><strong>Net Present Value ($):</strong> {{ project.npv | currency }}</p>
            <p><strong>Internal Rate Of Return (%):</strong> {{ project.irr }}</p>
            <p title="Payback in years">
              <strong>Estimated Payback Period:</strong>
              {{ project['Estimated Payback Period'] }}
            </p>
          </ul>
          <p><strong>Environmental Impact:</strong></p>
          <ul>
            <p><strong>Annual Emissions Reduction (%):</strong> {{ project['Annual Emissions Reduction'] }}</p>
            <p title="Per year in metric tons">
              <strong>Annual Fuel Reduction:</strong>
              {{ project['Annual Fuel Reduction'] }}
            </p>
          </ul>
          <p><strong>Technologies:</strong></p>
          <ul>
            <li *ngFor="let tech of project.Technologies">
              {{ tech.name }} ({{ tech.size }} {{ tech.unit }})
            </li>
          </ul>
          <p><strong>Custom Technologies:</strong></p>
          <ul>
            <li *ngFor="let customTech of project['Custom Technologies']">
              {{ customTech.description }}
            </li>
          </ul>
          <p><strong>Feedback:</strong></p>
          <div class="d-flex align-items-center justify-content-center">
            <!-- Upvotes Section -->
            <div class="d-flex align-items-center mx-2">
              <span>{{ project['Sum of Upvotes'] }}</span>
              <i class="fa fa-thumbs-up mx-1 text-success"></i> <!-- Green thumbs-up -->
              <!-- <button class="btn btn-secondary btn-sm px-2">
                  <i class="fa fa-arrow-up"></i>
                </button> -->
            </div>

            <!-- Downvotes Section -->
            <div class="d-flex align-items-center mx-2">
              <!-- <button class="btn btn-secondary btn-sm px-2">
                  <i class="fa fa-arrow-down"></i>
                </button> -->
              <i class="fa fa-thumbs-down mx-1 text-danger"></i> <!-- Red thumbs-down -->
              <span>{{ project['Sum of Downvotes'] }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>