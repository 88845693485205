import { BehaviorSubject, EMPTY, Observable } from 'rxjs'
import { map, switchMap, take } from 'rxjs/operators'

import { Injectable } from '@angular/core'
import { Action } from '@classes/Action'
import { Feature, FeatureCustomField } from '@classes/Feature'
import { FeatureProperty } from '@classes/FeatureProperty'
import { Interaction, InteractionOptions } from '@classes/Interaction'
import { ProjectService } from '@services/project.service'

import { ConnectionService } from './connection.service'
import { features$, FeatureService } from './feature.service'
import { SceneService } from './scene.service'

type Technology = {
  id: string
  name: string
  size: number
  unit: string
  description?: string | null
}

type CustomTechnology = {
  id: string
  description: string
}

type Project = {
  id: string
  'Project Name': string
  'Estimated Completion Date': string
  Latitude: number
  Longitude: number
  Location: string
  Status: 'Prioritization' | 'Assessment' | 'Procurement' | 'Deployment'
  'Project Description/Use Case': string
  'Project Size': string
  CapEx: number
  'Lifetime Savings': number
  NPV: number
  IRR: number
  'Estimated Payback Period': number
  'Annual Emissions Reduction': number
  'Annual Fuel Reduction': number
  Technologies: Technology[]
  'Custom Technologies': CustomTechnology[]
  'Sum of Upvotes': number | null
  'Sum of Downvotes': number | null
}

const vecktaProjectIDSubject = new BehaviorSubject<number>(null)

@Injectable({
  providedIn: 'root'
})
export class VecktaService {
  public projects: Project[] = []
  public id = 8000000000

  public vecktaProjectID$: Observable<number> = vecktaProjectIDSubject.pipe()

  get vecktaProjectID(): number { return vecktaProjectIDSubject.getValue() }
  set vecktaProjectID(projectID: number) { vecktaProjectIDSubject.next(projectID) }

  constructor(
    private connectionService: ConnectionService,
    private featureService: FeatureService,
    private sceneService: SceneService,
    private _projectService: ProjectService,
  ) { }

  getVecktaProjects() {
    this.sceneService.selectedScene$.pipe(
      switchMap(scene => {
        return features$.pipe(  // Added this to make sure that the features are there before adding to them.
          take(1),
          switchMap(() => {
            if (scene.veckta) {
              return this.connectionService.tryVecktaAPI(this._projectService.currentProject.title).pipe(
                map((projects: Project[]) => {
                  let groupID = this.id
                  this.id++
                  let features = []
                  this.projects = projects

                  this.projects.forEach(project => {
                    if (!this.featureService.features.find(f => f.name == project['Project Name'])) {
                      let feature = new Feature(
                        scene.id,
                        project['Project Name'],
                        "marker",
                        {
                          position: [project.Longitude, project.Latitude, 0],
                          properties: [
                            new FeatureProperty("integer", "size", "0.04"),
                            new FeatureProperty("boolean", "showOnTop", "false"),
                            new FeatureProperty("boolean", "scaleWithCamera", "false"),
                            new FeatureProperty("boolean", "trackCamera", "true"),
                            new FeatureProperty("string", "backgroundColor", "#FFFFFF"),
                            new FeatureProperty("string", "backgroundShape", "circle"),
                            new FeatureProperty("string", "color", "#000000"),
                            new FeatureProperty("string", "displayType", "icon"),
                            new FeatureProperty("string", "icon", "fas fa-lightbulb"),
                            new FeatureProperty("string", "text", "New Icon"),
                            new FeatureProperty("boolean", "veckta", "true")
                          ]
                        }
                      )
                      feature.id = this.id
                      this.id++
                      feature.parentID = groupID
                      feature.visible = true

                      // Interaction to open Veckta panel
                      let interClick = new Interaction(
                        'click',
                        [new Action('veckta', { projectID: project.id })],
                        { featureID: feature.id } as InteractionOptions
                      )

                      let interPanelClick = new Interaction(
                        'panel-click',
                        [new Action('veckta', { projectID: project.id })],
                        { featureID: feature.id } as InteractionOptions
                      )

                      feature.interactions = [interClick, interPanelClick]

                      let customFields = []
                      for (const key in project) {
                        if (key != "Latitude" && key != "Longitude") {
                          let value = project[key]
                          if (key == "Technologies" || key == "Custom Technologies") value = JSON.stringify(value)
                          const customField: FeatureCustomField = {
                            featureID: feature.id,
                            key: key,
                            value: value
                          }
                          customFields.push(customField)
                        }
                      }
                      feature.customFields = customFields

                      features.push(feature)
                    }
                  })

                  // Make group
                  if (features.length > 0) {
                    const group = new Feature(scene.id, "AK Energyshed Projects", 'group', { parentID: null, children: features })
                    group.id = groupID
                    group.visible = true
                    features.push(group)
                  }

                  features.push(...this.featureService.features)
                  this.featureService.features = features
                })
              )
            } else {
              return EMPTY
            }
          })
        )
      })
    ).subscribe()
  }
}