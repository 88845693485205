<div class="toast mt-2" role="alert" aria-live="assertive" aria-atomic="true">
    <!-- Toast Title -->
    <div class="toast-header">
        <span *ngIf="count > 1" class="badge text-dark bg-white">
            {{count}}
        </span>
        <i class='fas fa-square p-1' [ngStyle]="{'color': color, 'vertical-align': 'auto'}"></i>
        <strong class="me-auto">
            {{title}}
        </strong>
        <button class="btn btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>

    <!-- Toast Message -->
    <div *ngIf="message" class="toast-body text-muted ms-2 ">
        {{message}}
    </div>

    <!-- Progress Bar -->
    <div *ngIf='showProgressBar && percentDone < 100' class="progress m-1 p-1 bg-white" role="progressbar"
        aria-valuemin="0" aria-valuemax="100">
        <div class="progress-bar progress-bar-striped progress-bar-animated rounded" [style.width]="percentDone + '%'">
        </div>
    </div>

    <!-- Action Button -->
    <div *ngIf='actionButton?.callback' class='p-2 d-flex justify-content-start'>
        <button class="btn btn-sm w-100 text-light" [ngClass]="{
            'btn-primary': actionButton.color == 'blue' || actionButton.color == null,
            'btn-success': actionButton.color == 'green',
            'btn-danger': actionButton.color == 'red',
            'btn-warning': actionButton.color == 'yellow'
        }" (click)="actionButton?.callback()" data-bs-dismiss="toast">
            {{actionButton.title}}
        </button>
    </div>
</div>